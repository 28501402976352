import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/BillingDetails.css';
import axios from 'axios';

const BillingDetails = () => {
  const [filters, setFilters] = useState({
    dateFilter: 'before',
    date: '',
    customer_name: '',
    ref_no: '',
    approval_status: '',
  });

  const [customerNames, setCustomerNames] = useState([]);
  const [refNos, setRefNos] = useState([]);
  const [approvalStatuses, setApprovalStatuses] = useState([]);

  const [billingData, setBillingData] = useState([]);
  const [summary, setSummary] = useState({
    totalBills: 0,
    totalBillAmount: 0,
    totalTaxAmount: 0,
    totalAdvanceAmount: 0,
    totalNetAmount: 0,
  });

  const [dataLoaded, setDataLoaded] = useState(false);

  const navigate = useNavigate();

  const fetchBillingData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}billing/billingDetails/billing-data`, { params: filters });
      const { bills } = response.data;
  
      const totalBills = bills.length;
      const totalBillAmount = bills.reduce((sum, bill) => sum + parseFloat(bill.total_amount || 0), 0);
      const totalTaxAmount = bills.reduce((sum, bill) => sum + parseFloat(bill.tax || 0), 0);
      const totalAdvanceAmount = bills.reduce((sum, bill) => sum + parseFloat(bill.advance_amount || 0), 0);
      const totalNetAmount = bills.reduce((sum, bill) => sum + parseFloat(bill.net_amount || 0), 0);
  
      setBillingData(bills);
      setSummary({
        totalBills,
        totalBillAmount,
        totalTaxAmount,
        totalAdvanceAmount,
        totalNetAmount,
      });
      setDataLoaded(true);
    } catch (error) {
      console.error('Error fetching billing data:', error);
    }
  };
  

  // Fetch options for dropdowns
  const fetchOptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}billing/billingDetails/billing-options`);
      const { customerNames, refNos, approvalStatuses } = response.data;
      setCustomerNames(customerNames); // Set customer name options
      setRefNos(refNos);
      setApprovalStatuses(approvalStatuses);
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };

  useEffect(() => {
    fetchOptions();
    fetchBillingData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleView = () => {
    fetchBillingData();
  };

  const handleClearFilters = () => {
    setFilters({
      dateFilter: 'before',
      date: '',
      customer_name: '',
      ref_no: '',
      approval_status: '',
    });
    setBillingData([]); 
    setDataLoaded(false); 
  };

  const viewBillDetailsPage = (bill_no) => {
    window.open(`/billing/view/${bill_no}`, '_blank');
};

const viewJobDetailsPage = (ref_no) => {
    window.open(`/job/view/${ref_no}`, '_blank');
};


  return (
    <div className="bill-details-container">
  <div className="bill-details-filters">
    <h2 className="bill-details-header">Bill Details</h2>
    
    <div className="bill-details-filter-group">
      <label>Date Filter</label>
      <select 
        onChange={(e) => setFilters({ ...filters, dateFilter: e.target.value })} 
        value={filters.dateFilter}
        className="bill-details-select"
      >
        <option value="before">Before</option>
        <option value="after">After</option>
        <option value="equal">Equal</option>
      </select>
    </div>

    <div className="bill-details-filter-group">
      <label>Date</label>
      <input
        type="date"
        value={filters.date}
        onChange={(e) => setFilters({ ...filters, date: e.target.value })}
        className="bill-details-input"
      />
    </div>

    <div className="bill-details-filter-group">
      <label>Customer Name</label>
      <input
        list="customerNames"
        placeholder="Customer Name"
        onChange={(e) => setFilters({ ...filters, customer_name: e.target.value })}
        className="bill-details-input"
      />
      <datalist id="customerNames">
        {customerNames.map((name) => (
          <option key={name} value={name} />
        ))}
      </datalist>
    </div>

    <div className="bill-details-filter-group">
      <label>Reference No</label>
      <input
        list="refNos"
        placeholder="Reference No"
        onChange={(e) => setFilters({ ...filters, ref_no: e.target.value })}
        className="bill-details-input"
      />
      <datalist id="refNos">
        {refNos.map((ref) => (
          <option key={ref} value={ref} />
        ))}
      </datalist>
    </div>

    <div className="bill-details-filter-group">
      <label>Approval Status</label>
      <input
        list="approvalStatuses"
        placeholder="Approval Status"
        onChange={(e) => setFilters({ ...filters, approval_status: e.target.value })}
        className="bill-details-input"
      />
      <datalist id="approvalStatuses">
        {approvalStatuses.map((status) => (
          <option key={status} value={status} />
        ))}
      </datalist>
    </div>

    <div className="bill-details-actions">
      <button onClick={handleView} className="bill-details-button">View</button>
      <button onClick={handleClearFilters} className="bill-details-button">Clear</button>
    </div>
  </div>

  <div className="bill-details-summary">
    <p>Total Bills: {summary.totalBills}</p>
    <p>Total Bill Amount: {summary.totalBillAmount}</p>
    <p>Total Tax Amount: {summary.totalTaxAmount}</p>
    <p>Total Advance Amount: {summary.totalAdvanceAmount}</p>
    <p>Total Net Amount: {summary.totalNetAmount}</p>
  </div>

  {dataLoaded && (
    <table className="bill-details-table">
      <thead>
        <tr>
          <th>Bill No</th>
          <th>Customer Name</th>
          <th>Date</th>
          <th>Reference No</th>
          <th>Total Amount</th>
          <th>Tax Amount</th>
          <th>Advance Amount</th>
          <th>Net Amount</th>
          <th>Approval Status</th>
          <th>Bill View</th>
          <th>Job View</th>
        </tr>
      </thead>
      <tbody>
        {billingData.map((bill) => (
          <tr key={bill.bill_no}>
            <td>{bill.bill_no}</td>
            <td>{bill.customer_name}</td>
            <td>{new Date(bill.date).toLocaleDateString()}</td>
            <td>{bill.ref_no}</td>
            <td>{bill.total_amount}</td>
            <td>{bill.tax}</td>
            <td>{bill.advance_amount}</td>
            <td>{bill.net_amount}</td>
            <td>{bill.approval_status}</td>
            <td>
              <button onClick={() => viewBillDetailsPage(bill.bill_no)} className="bill-details-view-button">View Bill</button>
            </td>
            <td>
              <button onClick={() => viewJobDetailsPage(bill.ref_no)} className="bill-details-view-button">View Job</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )}
</div>

  );
};

export default BillingDetails;
