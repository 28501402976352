import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './styles/BillView.css';
import axios from 'axios';

const BillView = () => {
  const { bill_no } = useParams(); // Retrieve bill_no from URL params
  const [billDetails, setBillDetails] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch bill details when component loads
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}billing/billingDetails/bill/${bill_no}`, { params: { bill_no } })
      .then((response) => {
        setBillDetails(response.data); 
      })
      .catch((err) => {
        setError('Failed to fetch bill details.');
      });
  }, [bill_no]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!billDetails) {
    return <div>Loading...</div>;
  }


  return (
<div className="bill-view-table-container">
  <h1 className="bill-view-table-header">Bill View for Bill Number: {billDetails.bill_no}</h1>

  <table className="bill-view-table">
    <tbody>
      <tr>
        <th>Year</th>
        <td>{billDetails.year}</td>
      </tr>
      <tr>
        <th>Date</th>
        <td>{billDetails.date}</td>
      </tr>
      <tr>
        <th>Reference No</th>
        <td>{billDetails.ref_no}</td>
      </tr>
      <tr>
        <th>Customer Name</th>
        <td>{billDetails.customer_name}</td>
      </tr>
      <tr>
        <th>Total Amount</th>
        <td>{billDetails.total_amount}</td>
      </tr>
      <tr>
        <th>Tax</th>
        <td>{billDetails.tax}</td>
      </tr>
      <tr>
        <th>Gross Amount</th>
        <td>{billDetails.gross_amount}</td>
      </tr>
      <tr>
        <th>Advance Amount</th>
        <td>{billDetails.advance_amount}</td>
      </tr>
      <tr>
        <th>Net Amount</th>
        <td>{billDetails.net_amount}</td>
      </tr>
      <tr>
        <th>Approval Status</th>
        <td>{billDetails.approval_status}</td>
      </tr>
      <tr>
        <th>Service Type</th>
        <td>{billDetails.service_type}</td>
      </tr>
      <tr>
        <th>Trans Mode</th>
        <td>{billDetails.trans_mode}</td>
      </tr>
      <tr>
        <th>Private Billing</th>
        <td>{billDetails.private_billing}</td>
      </tr>
      <tr>
        <th>Prepared By</th>
        <td>{billDetails.prepared_by}</td>
      </tr>
      <tr>
        <th>Approved By</th>
        <td>{billDetails.approved_by}</td>
      </tr>
      <tr>
        <th>Paid Date</th>
        <td>{billDetails.paid_date}</td>
      </tr>
      <tr>
        <th>Received Amount</th>
        <td>{billDetails.rcvd_amount}</td>
      </tr>
      <tr>
        <th>Deduction</th>
        <td>{billDetails.deduction}</td>
      </tr>
      <tr>
        <th>Cheque</th>
        <td>{billDetails.cheque}</td>
      </tr>
      <tr>
        <th>Cheque Date</th>
        <td>{billDetails.cheque_date}</td>
      </tr>
      <tr>
        <th>Balance</th>
        <td>{billDetails.balance}</td>
      </tr>
    </tbody>
  </table>

  <div className="bill-view-summary">
  <h2>Summary</h2>
  <table className="bill-summary-table">
    <tbody>
      <tr>
        <td><strong>Total Amount:</strong></td>
        <td>{billDetails.total_amount}</td>
      </tr>
      <tr>
        <td><strong>Tax Amount:</strong></td>
        <td>{billDetails.tax}</td>
      </tr>
      <tr>
        <td><strong>Advance Amount:</strong></td>
        <td>{billDetails.advance_amount}</td>
      </tr>
      <tr>
        <td><strong>Net Amount:</strong></td>
        <td>{billDetails.net_amount}</td>
      </tr>
      <tr>
        <td><strong>Balance Due:</strong></td>
        <td>{billDetails.balance}</td>
      </tr>
      <tr>
        <td><strong>Approval Status:</strong></td>
        <td>{billDetails.approval_status}</td>
      </tr>
    </tbody>
  </table>
</div>

</div>

  );
};

export default BillView;
