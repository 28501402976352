import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './styles/VoucherEntry.css';  // Ensure you create a CSS file to style this component
import { AuthContext } from '../AuthContext';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const VoucherEntry = () => {
  const [voucherNumber, setVoucherNumber] = useState(1); // Start with 00001
  const [selectedType, setSelectedType] = useState('');
  const [formData, setFormData] = useState({
    date: new Date().toISOString().split('T')[0],
    expenseType: '',
    voucher_head: '',
    refNo: '',
    serviceType: '',
    mode: '',
    party: '',
    netWeight: '',
    grossWeight: '',
    expenses: '',
    expensesAmt: '',
    totalAmt: '',
    paymentMode: 'cash',
    cheque: '',
    paymentDate: '',
    inFavourOf: '',
    bankName: '',
    remarks: '',
  });
  const [importJobData, setImportJobData] = useState([]);
  const [transporterData, setTransporterData] = useState([]);
  const [names, setNames] = useState([]);
  const { user } = useContext(AuthContext);
  const username = user.username;

  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + 'latest-voucher')
    .then(response => {
      const latestVoucher = response.data.latestVoucher;
      setVoucherNumber(latestVoucher + 1); // Set next voucher number
    })
    .catch(error => console.error('Error fetching latest voucher number:', error));
    // Fetch data from import_job and transporter_master tables
    axios.get(process.env.REACT_APP_BACKEND_URL + 'import-job/ref-numbers')
      .then(response => setImportJobData(response.data))
      .catch(error => console.error('Error fetching import job data:', error));

    axios.get(process.env.REACT_APP_BACKEND_URL + 'transporter-master')
      .then(response => setTransporterData(response.data))
      .catch(error => console.error('Error fetching transporter data:', error));

      axios.get(BASE_URL + 'names')
      .then(response => setNames(response.data))
      .catch(error => console.error('Error fetching names:', error));
  }, []);

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFetchDetails = () => {
    if (formData.refNo) {
      axios.get(BASE_URL + `voucher-entry/${formData.refNo}`)
        .then(response => {
          const jobDetails = response.data;
          setFormData(prevData => ({
            ...prevData,
            serviceType: jobDetails.service_type,
            mode: jobDetails.trans_mode,
            party: jobDetails.importer_name,
            netWeight: jobDetails.net_wt,
            grossWeight: jobDetails.gross_wt
          }));
        })
        .catch(error => console.error('Error fetching import job details:', error));
    } else {
      alert('Please select a Ref No first.');
    }
  };

  const handleSave = () => {
    axios.post(process.env.REACT_APP_BACKEND_URL + 'voucher-entry', {
      ...formData,
      voucherNumber,
      preparedBy: username
    })
    .then(() => {
      alert('Voucher saved successfully');
      axios.get(process.env.REACT_APP_BACKEND_URL + 'latest-voucher')
        .then(response => {
          const latestVoucher = response.data.latestVoucher;
          setVoucherNumber(latestVoucher + 1); // Update voucher number
        })
        .catch(error => console.error('Error fetching latest voucher number after saving:', error));
      setFormData({  // Reset form after saving
        date: '',
        expenseType: '',
        voucher_head: '',
        refNo: '',
        serviceType: '',
        mode: '',
        party: '',
        netWeight: '',
        grossWeight: '',
        expenses: '',
        expensesAmt: '',
        totalAmt: '',
        paymentMode: 'cash',
        cheque: '',
        paymentDate: '',
        inFavourOf: '',
        bankName: '',
        remarks: '',
      });
    })
    .catch(error => console.error('Error saving voucher:', error));
  };

  

  return (
    <div className="voucher-entry">
      <div className="voucher-entry-title">
      <h1>Voucher Entry</h1></div>
      <div className="voucher-entry-type">
        <label>Select Type: </label>
        <select onChange={handleTypeChange} value={selectedType}>
          <option value="">Select Type</option>
          <option value="general">General</option>
          <option value="accounts">Accounts</option>
        </select>
      </div>

      {selectedType === 'general' && (
        <div className="voucher-form">
          <div className="form-group">
            <label>Voucher Number:</label>
            <input type="text" value={String(voucherNumber).padStart(5, '0')} readOnly />
          </div>
          <div className="form-group">
            <label>Date:</label>
            <input type="date" name="date" value={formData.date} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Expense Type:</label>
            <input type="text" name="expenseType" value={formData.expenseType} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Voucher Head:</label>
            <input
              list="voucherHeadOptions"
              name="voucher_head"
              value={formData.voucher_head}
              onChange={handleInputChange}
            />
            <datalist id="voucherHeadOptions">
              {names.map((name, index) => (
                <option key={index} value={name.name} />
              ))}
            </datalist>
          </div>
          <div className="form-group">
            <label>Expenses:</label>
            <input type="text" name="expenses" value={formData.expenses} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Expenses amount:</label>
            <input type="number" name="expensesAmt" value={formData.expensesAmt} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Total amount:</label>
            <input type="number" name="totalAmt" value={formData.totalAmt} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Prepared By:</label>
            <input type="text" value={username} readOnly />
          </div>
          <div className="form-group">
            <label>Payment Mode:</label>
            <select name="paymentMode" value={formData.paymentMode} onChange={handleInputChange}>
              <option value="cash">Cash</option>
              <option value="cheque">Cheque</option>
            </select>
          </div>
          {formData.paymentMode === 'cheque' && (
            <div className="form-group">
              <label>Cheque:</label>
              <input type="text" name="cheque" value={formData.cheque} onChange={handleInputChange} />
            </div>
          )}
          <div className="form-group">
            <label>Payment Date:</label>
            <input type="date" name="paymentDate" value={formData.paymentDate} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>In Favour Of:</label>
            <select name="inFavourOf" value={formData.inFavourOf} onChange={handleInputChange}>
              <option value="">Select In Favour Of</option>
              {transporterData.map(transporter => (
                <option key={transporter.transporter_name} value={transporter.transporter_name}>{transporter.transporter_name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Bank Name:</label>
            <input type="text" name="bankName" value={formData.bankName} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Remarks:</label>
            <input type="text" name="remarks" value={formData.remarks} onChange={handleInputChange} />
          </div>
          <div className="button-group">
            <button onClick={handleSave}>Save</button>
            <button onClick={() => setFormData({})}>Cancel</button>
          </div>
        </div>
      )}

      

      {selectedType === 'accounts' && (
        <div className="voucher-form">
          <div className="form-group">
            <label>Voucher Number:</label>
            <input type="text" value={String(voucherNumber).padStart(5, '0')} readOnly />
          </div>
          <div className="form-group">
            <label>Date:</label>
            <input type="date" name="date" value={formData.date} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Expense Type:</label>
            <input type="text" name="expenseType" value={formData.expenseType} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Enter Ref No:</label>
            <select name="refNo" value={formData.refNo} onChange={handleInputChange}>
              <option value="">Select Ref No</option>
              {importJobData.map(job => (
                <option key={job.ref_no} value={job.ref_no}>{job.ref_no}</option>
              ))}
            </select>
            <button className='fetch-details' onClick={handleFetchDetails}>Fetch Details</button>
          </div>
          <div className="form-group">
            <label>Service Type:</label>
            <input type="text" name="serviceType" value={formData.serviceType} readOnly />
          </div>
          <div className="form-group">
            <label>Mode:</label>
            <input type="text" name="mode" value={formData.mode} readOnly />
          </div>
          <div className="form-group">
            <label>Party:</label>
            <input type="text" name="party" value={formData.party} readOnly />
          </div>
          <div className="form-group">
            <label>Net Weight:</label>
            <input type="text" name="netWeight" value={formData.netWeight} readOnly />
          </div>
          <div className="form-group">
            <label>Gross Weight:</label>
            <input type="text" name="grossWeight" value={formData.grossWeight} readOnly />
          </div>
          <div className="form-group">
            <label>Expenses:</label>
            <input type="text" name="expenses" value={formData.expenses} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Expenses amount:</label>
            <input type="number" name="expensesAmt" value={formData.expensesAmt} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Total amount:</label>
            <input type="number" name="totalAmt" value={formData.totalAmt} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Prepared By:</label>
            <input type="text" value={username} readOnly />
          </div>
          <div className="form-group">
            <label>Payment Mode:</label>
            <select name="paymentMode" value={formData.paymentMode} onChange={handleInputChange}>
              <option value="cash">Cash</option>
              <option value="cheque">Cheque</option>
            </select>
          </div>
          {formData.paymentMode === 'cheque' && (
            <div className="form-group">
              <label>Cheque:</label>
              <input type="text" name="cheque" value={formData.cheque} onChange={handleInputChange} />
            </div>
          )}
          <div className="form-group">
            <label>Payment Date:</label>
            <input type="date" name="paymentDate" value={formData.paymentDate} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>In Favour Of:</label>
            <select name="inFavourOf" value={formData.inFavourOf} onChange={handleInputChange}>
              <option value="">Select In Favour Of</option>
              {transporterData.map(transporter => (
                <option key={transporter.transporter_name} value={transporter.transporter_name}>{transporter.transporter_name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Bank Name:</label>
            <input type="text" name="bankName" value={formData.bankName} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Remarks:</label>
            <input type="text" name="remarks" value={formData.remarks} onChange={handleInputChange} />
          </div>
          <div className="button-group">
            <button className='save' onClick={handleSave}>Save</button>
            <button className='cancel' onClick={() => setFormData({})}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VoucherEntry;
