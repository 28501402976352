import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import './styles/ClearanceRegisterReport.css';

const ClearanceRegisterReport = () => {
  const [filters, setFilters] = useState({
    trans_mode: '',
    dateFilter: 'before',
    date: '',
    importer_name: '',
    year: '',
  });

  const [years, setYears] = useState([]);
  const [importers, setImporters] = useState([]);
  const [beTypes, setBeTypes] = useState([]);
  const [clearanceData, setClearanceData] = useState([]);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}incomplete-jobs/report/options`);
      const { years, importers, beTypes } = response.data;
      setYears(years);
      setImporters(importers);
      setBeTypes(beTypes);
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };

  const fetchClearanceData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}incomplete-jobs/report/data`, {
        params: filters,
      });
      setClearanceData(response.data.tableData);
      setBeTypes(response.data.beTypesArray);
    } catch (error) {
      console.error('Error fetching clearance data:', error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const handleView = () => {
    fetchClearanceData();
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['S.No', 'Importer Name', ...beTypes, 'Total']],
      body: clearanceData.map((row, index) => [
        index + 1,
        row.importer_name,
        ...beTypes.map(beType => row[beType] || 0),
        row.total,
      ]),
    });
    doc.save('clearance_register_report.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      clearanceData.map((row, index) => ({
        SNo: index + 1,
        ImporterName: row.importer_name,
        ...Object.fromEntries(beTypes.map(beType => [beType, row[beType] || 0])),
        Total: row.total,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clearance Register');
    XLSX.writeFile(workbook, 'clearance_register_report.xlsx');
  };

  return (
    <div className="clearance-register-container">
      <h2 className="clearance-title">Clearance Register</h2>
  
      <div className="clearance-filters">
        {/* Filter components */}
        <div className="clearance-filter-group">
          <label className="clearance-label">Transport Mode</label>
          <select 
            className="clearance-dropdown"
            onChange={(e) => setFilters({ ...filters, trans_mode: e.target.value })}
          >
            <option value="">Select</option>
            <option value="Air">Air</option>
            <option value="Sea">Sea</option>
            <option value="Road">Road</option>
            <option value="Courier">Courier</option>
          </select>
        </div>
        <div className="clearance-filter-group">
          <label className="clearance-label">Date Filter</label>
          <select 
            className="clearance-dropdown"
            onChange={(e) => setFilters({ ...filters, dateFilter: e.target.value })}
          >
            <option value="before">Before</option>
            <option value="equal">Equal</option>
            <option value="after">After</option>
          </select>
        </div>
        <div className="clearance-filter-group">
          <label className="clearance-label">Date</label>
          <input 
            className="clearance-input" 
            type="date"
            onChange={(e) => setFilters({ ...filters, date: e.target.value })} 
          />
        </div>
        <div className="clearance-filter-group">
          <label className="clearance-label">Importer Name</label>
          <input 
            className="clearance-input" 
            list="importerNames" 
            onChange={(e) => setFilters({ ...filters, importer_name: e.target.value })} 
          />
          <datalist id="importerNames">
            {importers.map((importer) => (
              <option key={importer} value={importer} />
            ))}
          </datalist>
        </div>
        <div className="clearance-filter-group">
          <label className="clearance-label">Year</label>
          <select 
            className="clearance-dropdown" 
            onChange={(e) => setFilters({ ...filters, year: e.target.value })}
          >
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="all-voucher-button-group">
          <button className="clearance-button" onClick={handleView}>View</button>
          <button className="file-upload-download-button" onClick={downloadPDF}>Download PDF</button>
          <button className="file-upload-download-button" onClick={downloadExcel}>Download Excel</button>
        </div>
      </div>
  
      <table className="clearance-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Importer Name</th>
            {beTypes.map(beType => (
              <th key={beType}>{beType}</th>
            ))}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {clearanceData.map((row, index) => (
            <tr key={row.importer_name}>
              <td>{index + 1}</td>
              <td>{row.importer_name}</td>
              {beTypes.map(beType => (
                <td key={beType}>{row[beType] || 0}</td>
              ))}
              <td>{row.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClearanceRegisterReport;
