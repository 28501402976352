import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles/ManageRoles.css";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const pageMappings = {
  Dashboard: "Dashboard",
  TaxMaster: "Tax Master",
  TransporterMaster: "Transporter Master",
  UOMMaster: "UOM Master",
  ClientMaster: "Client Master",
  ClientDetails: "Client Details",
  RateMaster: "Rate Master",
  RateDetails: "Rate Details",
  BillTax: "Bill Tax",
  ChargesMaster: "Charges Master",
  InvoiceHead: "Invoice Head",
  ManageUsers: "Manage Users",
  ManageRoles: "Manage Roles",
  ImportJobRegistration: "Job Registration",
  ImportJobUpdation: "Job Updation",
  JobDetails: "Job Details",
  AllVoucherDetails: "All Voucher Details",
  FileDelete: "File Delete",
  FileUpload: "File Upload",
  IncompleteJobs: "Incomplete Jobs",
  Accounts: "Accounts",
  Billing: "Billing",
  BillPayments: "Bill Payments",
  BillingDetails: "Billing Details",
  BillView: "Bill View",
  JobView: "Job View",
  CompanyReport: "Company Report",
  DeletedInformation: "Deleted Information",
  GSTPrintApprove: "GST Print and Approve",
  PrivatePrintApprove: "Private Bill Approve and Print",
  PrivateBilling: "Private Billing",
  UnbilledInformation: "Unbilled Information",
  VoucherEntry: "Voucher Entry",
  VoucherPayment: "Voucher Payment",
  VoucherPrintApprove: "Voucher Print Approve",
  Reports: "Reports",
  BillOutstandingReport: "Bill Outstanding Report",
  BillPaymentDetailsReport: "Bill Payment Details Report",
  BondHistoryDetailsReport: "Bond History Details Report",
  ClearanceRegisterReport: "Clearance Register Report",
  FileUploadDetailsReport: "File Upload Details Report",
  JobDetailsReport: "Job Details Report",
  ServiceTaxReport: "Service Tax Report",
  UnclearedJobsReport: "Uncleared Jobs Report",
  VoucherDetailsReport: "Voucher Details Report",
  Search: "Search",
  ViewLog: "View Log",
  Profile: "Profile",
};

// Grouping pages by category
const categorizedRoutes = {
  "Master Operations": [
    "TaxMaster",
    "TransporterMaster",
    "UOMMaster",
    "ClientMaster",
    "ClientDetails",
    "RateMaster",
    "RateDetails",
    "ChargesMaster",
    "InvoiceHead",
    "BillTax",
  ],
  "Job Operations": [
    "ImportJobRegistration",
    "ImportJobUpdation",
    "JobDetails",
    "AllVoucherDetails",
    "FileDelete",
    "FileUpload",
    "IncompleteJobs",
  ],
  "Accounts Operations": [
    "Accounts",
    "Billing",
    "BillPayments",
    "BillingDetails",
    "BillView",
    "JobView",
    "CompanyReport",
    "DeletedInformation",
    "GSTPrintApprove",
    "PrivateBilling",
    "PrivatePrintApprove",	
    "UnbilledInformation",
    "VoucherEntry",
    "VoucherPayment",
    "VoucherPrintApprove",
  ],
  "Reports Operations": [
    "Reports",
    "BillOutstandingReport",
    "BillPaymentDetailsReport",
    "BondHistoryDetailsReport",
    "ClearanceRegisterReport",
    "FileUploadDetailsReport",
    "JobDetailsReport",
    "ServiceTaxReport",
    "UnclearedJobsReport",
    "VoucherDetailsReport",
  ],
  "Search Operations": ["Search"],
  "Log Operations": ["ViewLog"],
  "User Management": [    "ManageUsers",
    "ManageRoles",],
  "Profile Management": [ "Profile"]
};

const ManageRoles = () => {
  const [roles, setRoles] = useState([]);
  const [newRoleName, setNewRoleName] = useState("");
  const [selectedRoutes, setSelectedRoutes] = useState(["Dashboard"]); // Dashboard is always selected
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState("");

  // Fetch all roles
  const fetchRoles = async () => {
    try {
      const response = await axios.get(BASE_URL + "/roles");
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleAddRole = async () => {
    const routes = selectedRoutes.join(",");

    try {
      await axios.post(`${BASE_URL}/roles`, { roleName: newRoleName, routes });

      setMessage("Role added successfully");
      setNewRoleName("");
      setSelectedRoutes(["Dashboard"]);
      fetchRoles(); // Refresh roles list
      setTimeout(() => setMessage(""), 3000); // Clear message after 3 seconds
    } catch (error) {
      console.error(
        "Error adding role:",
        error.response ? error.response.data : error.message
      );
      setMessage("Error adding role");
      setTimeout(() => setMessage(""), 3000);
    }
  };

  const handleEditRole = async () => {
    
    const routes = selectedRoutes.join(",");
    try {
      await axios.put(BASE_URL + `/roles/${selectedRoleId}`, {
        roleName: newRoleName,
        routes,
      });
      setMessage("Role updated successfully");
      setNewRoleName("");
      setSelectedRoutes(["Dashboard"]);
      setEditMode(false);
      fetchRoles(); // Re-fetch roles after editing role
      setShowAddRolePopup(false);
      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      console.error("Error updating role:", error);
      setMessage("Error updating role");
      setTimeout(() => setMessage(""), 3000);
    }
  };

  const handleDeleteRole = async (roleId) => {
    if (window.confirm("Are you sure you want to delete this role?")) {
      try {
        await axios.delete(BASE_URL + `/roles/${roleId}`);
        setMessage("Role deleted successfully");
        fetchRoles(); // Refresh roles list
        setTimeout(() => setMessage(""), 3000);
        
      } catch (error) {
        console.error("Error deleting role:", error);
        setMessage("Error deleting role");
        setTimeout(() => setMessage(""), 3000);
      }
    }
  };

  const handleSelectRole = (role) => {
    setShowAddRolePopup(true);
    setSelectedRoleId(role.id);
    setNewRoleName(role.role_name);
    setSelectedRoutes([
      "Dashboard",
      ...role.routes.split(",").filter((r) => r !== "Dashboard"),
    ]);
    setEditMode(true);
  };

  const handleRouteChange = (route) => {
    if (selectedRoutes.includes(route)) {
      setSelectedRoutes(selectedRoutes.filter((r) => r !== route));
    } else {
      setSelectedRoutes([...selectedRoutes, route]);
    }
  };

  const handleSelectAll = (category) => {
    const allCategoryRoutes = categorizedRoutes[category];

    if (allCategoryRoutes.every((route) => selectedRoutes.includes(route))) {
      setSelectedRoutes(
        selectedRoutes.filter((route) => !allCategoryRoutes.includes(route))
      );
    } else {
      const currentSelectedRoutes = selectedRoutes.filter(
        (route) => !allCategoryRoutes.includes(route)
      );
      const newSelectedRoutes = currentSelectedRoutes.concat(allCategoryRoutes);
      setSelectedRoutes([
        "Dashboard",
        ...newSelectedRoutes.filter((r) => r !== "Dashboard"),
      ]);
    }
  };

  const isCategorySelected = (category) => {
    const allCategoryRoutes = categorizedRoutes[category];
    return allCategoryRoutes.every((route) => selectedRoutes.includes(route));
  };

  const [showAddRolePopup, setShowAddRolePopup] = useState(false);

  const handleCancelEdit = () => {
    setNewRoleName("");
    setSelectedRoutes(["Dashboard"]);
    setEditMode(false);
    setShowAddRolePopup(false);
  };

  return (
    <div className="manage-roles">
      <h1 className="manage-roles-title">
        {editMode ? "Edit Role" : "Manage Roles"}
      </h1>

      <button
        onClick={() => setShowAddRolePopup(true)}
        className="manage-roles-button add-button"
      >
        Add Role
      </button>
      {showAddRolePopup && (
        <div
          className="backdrop"
         
        >
          <div className="add-role">
            <h2 className="manage-roles-subtitle">
              {editMode ? "Edit Role" : "Add a New Role"}
            </h2>
            <input
              type="text"
              value={newRoleName}
              onChange={(e) => setNewRoleName(e.target.value)}
              placeholder="Role Name"
              className="manage-roles-form-input"
            />
            <div className="routes-selection">
              <h3>Select Components</h3>
              <div className="checkbox-group">
                {/* Dashboard Checkbox */}
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="Dashboard"
                    value="Dashboard"
                    checked={true}
                    readOnly
                  />
                  <label htmlFor="Dashboard">Dashboard</label>
                </div>

                {/* Grouped Routes */}
                <div className="access-selection">
                {Object.keys(categorizedRoutes).map((category) => (
                  <div key={category} className="category-section">
                    <div className="category-header">
                      <input
                        type="checkbox"
                        id={category}
                        checked={isCategorySelected(category)}
                        onChange={() => handleSelectAll(category)}
                      />
                      <label htmlFor={category}>{category}</label>
                    </div>
                    {categorizedRoutes[category].map((route) => (
                      <div key={route} className="checkbox-item">
                        <input
                          type="checkbox"
                          id={route}
                          value={route}
                          checked={selectedRoutes.includes(route)}
                          onChange={() => handleRouteChange(route)}
                        />
                        <label htmlFor={route}>{pageMappings[route]}</label>
                      </div>
                    ))}
                  </div>
                ))}
                </div>
              </div>
            </div>
            <div className="managebuttons">
            <button
              onClick={editMode ? handleEditRole : handleAddRole}
              className={`manage-roles-button ${
                editMode ? "edit-button" : "add-button"
              }`}
            >
              {editMode ? "Update Role" : "Add Role"}
            </button>
            
              <button
                onClick={handleCancelEdit}
                className="manage-roles-button cancel-button"
              >
                Cancel
              </button>
            </div>
            

          </div>
        </div>
      )}

      <h2 className="manage-roles-subtitle">Existing Roles</h2>
      <table className="roles-table">
        <thead>
          <tr>
            <th>Role Name</th>
            <th>Permissions</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((role) => (
            <tr key={role.id} className="role-item">
              <td>{role.role_name}</td>
              <td>
                {role.routes
                  .split(",")
                  .map((route) => pageMappings[route] || route)
                  .join(", ")}
              </td>
              <td className="role-actions">
                <button
                  className="edit-role-button"
                  onClick={() => handleSelectRole(role)}
                >
                  Edit
                </button>
                <button
                  className="delete-role-button"
                  onClick={() => handleDeleteRole(role.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default ManageRoles;
