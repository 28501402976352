import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/BillingDetails.css';
import jsPDF from 'jspdf';
import { utils, writeFile } from 'xlsx';
import 'jspdf-autotable';
import axios from 'axios';

const BillPaymentDetailsReport = () => {
  const [filters, setFilters] = useState({
    dateFilter: 'before',
    date: '',
    customer_name: '',
    ref_no: '',
  });

  const [customerNames, setCustomerNames] = useState([]);
  const [refNos, setRefNos] = useState([]);

  const [billingData, setBillingData] = useState([]);
  const [summary, setSummary] = useState({
    totalBills: 0,
    totalBillAmount: 0,
    totalTaxAmount: 0,
    totalAdvanceAmount: 0,
    totalNetAmount: 0,
    totalBalance: 0,
  });

  const [dataLoaded, setDataLoaded] = useState(false);
  const [viewMode, setViewMode] = useState('detailed'); // New state for view mode

  const navigate = useNavigate();

  const fetchBillingData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}billing/billingDetails/billing-data`, { params: filters });
      const { bills } = response.data;

      const totalBills = bills.length;
      const totalBillAmount = bills.reduce((sum, bill) => sum + parseFloat(bill.total_amount || 0), 0);
      const totalTaxAmount = bills.reduce((sum, bill) => sum + parseFloat(bill.tax || 0), 0);
      const totalAdvanceAmount = bills.reduce((sum, bill) => sum + parseFloat(bill.advance_amount || 0), 0);
      const totalNetAmount = bills.reduce((sum, bill) => sum + parseFloat(bill.net_amount || 0), 0);
      const totalBalance = bills.reduce((sum, bill) => sum + parseFloat(bill.balance || 0), 0); // Calculate total balance

      setBillingData(bills);
      setSummary({
        totalBills,
        totalBillAmount,
        totalTaxAmount,
        totalAdvanceAmount,
        totalNetAmount,
        totalBalance,
      });
      setDataLoaded(true);
    } catch (error) {
      console.error('Error fetching billing data:', error);
    }
  };

  // Fetch options for dropdowns
  const fetchOptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}billing/billingDetails/billing-options`);
      const { customerNames, refNos } = response.data; // Removed approval statuses
      setCustomerNames(customerNames);
      setRefNos(refNos);
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };

  useEffect(() => {
    fetchOptions();
    fetchBillingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleView = () => {
    fetchBillingData();
  };

  const handleClearFilters = () => {
    setFilters({
      dateFilter: 'before',
      date: '',
      customer_name: '',
      ref_no: '',
    });
    setBillingData([]);
    setDataLoaded(false);
  };

  const viewBillDetailsPage = (bill_no) => {
    window.open(`/billing/view/${bill_no}`, '_blank');
  };

  const viewJobDetailsPage = (ref_no) => {
    window.open(`/job/view/${ref_no}`, '_blank');
  };

  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === 'detailed' ? 'summary' : 'detailed'));
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
  
    // Title
    doc.setFontSize(14);
    doc.text('Bill Payment Details Report', 14, 10);
    doc.setFontSize(10);
    
    // Column headers
    const tableColumn = [
      'Bill No',
      'Customer Name',
      'Date',
      'Reference No',
      'Total Amount',
      'Tax Amount',
      'Advance Amount',
      'Net Amount',
      'Balance',
    ];
  
    // Data rows
    const tableRows = billingData.map((bill) => [
      bill.bill_no,
      bill.customer_name,
      bill.date,
      bill.ref_no,
      bill.total_amount,
      bill.tax,
      bill.advance_amount,
      bill.net_amount,
      bill.balance,
    ]);
  
    // Add a row for totals
    const totalRow = [
      'Total',
      '',
      '', // Empty cell for date
      '', // Empty cell for reference number
      summary.totalBillAmount.toFixed(2),
      summary.totalTaxAmount.toFixed(2),
      summary.totalAdvanceAmount.toFixed(2),
      summary.totalNetAmount.toFixed(2),
      summary.totalBalance.toFixed(2),
    ];
    
    // Generate PDF table
    doc.autoTable({
      head: [tableColumn],
      body: [...tableRows, totalRow], // Include the total row
      startY: 20, // Position of the table
      margin: { horizontal: 10 },
      styles: {
        cellPadding: 5,
        fontSize: 10,
        overflow: 'linebreak',
        lineHeight: 1.5,
      },
      headStyles: {
        fillColor: [22, 160, 133], // Change header background color
        textColor: [255, 255, 255], // Change header text color
      },
      didParseCell: function (data) {
        // If the cell contains a number, right-align it
        if (data.column.dataKey === 'Total Amount' || data.column.dataKey === 'Tax Amount' || 
            data.column.dataKey === 'Advance Amount' || data.column.dataKey === 'Net Amount' || 
            data.column.dataKey === 'Balance') {
          data.cell.styles.halign = 'right';
        }
      },
      didDrawCell: function (data) {
        // Highlight the total row
        if (data.row.index === tableRows.length) {
          data.cell.styles.fillColor = [230, 230, 230]; // Light gray color for total row
        }
      },
    });
  
    // Save the PDF
    doc.save('bill_payment_details_report.pdf');
  };
  const downloadExcel = () => {
    const ws = utils.json_to_sheet(billingData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Bill Payment Details');
  
    // Create file and save
    writeFile(wb, 'bill_payment_details_report.xlsx');
  };


  return (
    <div className="bill-details-container">
      <div className="bill-details-filters">
        <h2 className="bill-details-header">Bill Payments Details Report</h2>

        <div className="bill-details-filter-group">
          <label>Date Filter</label>
          <select 
            onChange={(e) => setFilters({ ...filters, dateFilter: e.target.value })} 
            value={filters.dateFilter}
            className="bill-details-select"
          >
            <option value="before">Before</option>
            <option value="after">After</option>
            <option value="equal">Equal</option>
          </select>
        </div>

        <div className="bill-details-filter-group">
          <label>Date</label>
          <input
            type="date"
            value={filters.date}
            onChange={(e) => setFilters({ ...filters, date: e.target.value })}
            className="bill-details-input"
          />
        </div>

        <div className="bill-details-filter-group">
          <label>Customer Name</label>
          <input
            list="customerNames"
            placeholder="Customer Name"
            onChange={(e) => setFilters({ ...filters, customer_name: e.target.value })}
            className="bill-details-input"
          />
          <datalist id="customerNames">
            {customerNames.map((name) => (
              <option key={name} value={name} />
            ))}
          </datalist>
        </div>

        <div className="bill-details-filter-group">
          <label>Reference No</label>
          <input
            list="refNos"
            placeholder="Reference No"
            onChange={(e) => setFilters({ ...filters, ref_no: e.target.value })}
            className="bill-details-input"
          />
          <datalist id="refNos">
            {refNos.map((ref) => (
              <option key={ref} value={ref} />
            ))}
          </datalist>
        </div>

        <div className="bill-details-actions">
          <button onClick={handleView} className="bill-details-button">View</button>
          <button onClick={handleClearFilters} className="bill-details-button">Clear</button>
          <button onClick={toggleViewMode} className="bill-details-button">
            {viewMode === 'detailed' ? 'Switch to Summary' : 'Switch to Detailed'}
          </button>
          <button onClick={downloadPDF} className="file-upload-download-button">Download PDF</button>
          <button onClick={downloadExcel} className="file-upload-download-button">Download Excel</button>
        </div>
      </div>

      <div className="bill-details-summary">
        <p>Total Bills: {summary.totalBills}</p>
        <p>Total Bill Amount: {summary.totalBillAmount}</p>
        <p>Total Tax Amount: {summary.totalTaxAmount}</p>
        <p>Total Advance Amount: {summary.totalAdvanceAmount}</p>
        <p>Total Net Amount: {summary.totalNetAmount}</p>
        <p>Total Balance: {summary.totalBalance}</p> {/* New total balance */}
      </div>

      {dataLoaded && (
        <table className="bill-details-table">
          <thead>
            <tr>
              <th>Bill No</th>
              <th>Customer Name</th>
              <th>Date</th>
              <th>Reference No</th>
              <th>Total Amount</th>
              <th>Tax Amount</th>
              <th>Advance Amount</th>
              <th>Net Amount</th>
              <th>Balance</th> {/* New balance column */}
            </tr>
          </thead>
          <tbody>
            {viewMode === 'detailed' ? (
              <>
                {billingData.map((bill) => (
                  <tr key={bill.bill_no}>
                    <td>{bill.bill_no}</td>
                    <td>{bill.customer_name}</td>
                    <td>{new Date(bill.date).toLocaleDateString()}</td>
                    <td>{bill.ref_no}</td>
                    <td>{bill.total_amount}</td>
                    <td>{bill.tax}</td>
                    <td>{bill.advance_amount}</td>
                    <td>{bill.net_amount}</td>
                    <td>{bill.balance}</td> {/* New balance cell */}
                  </tr>
                ))}
                <tr style={{ backgroundColor: '#f0f0f0' }}>
                  <td colSpan="4"><strong>Total:</strong></td>
                  <td>{summary.totalBillAmount}</td>
                  <td>{summary.totalTaxAmount}</td>
                  <td>{summary.totalAdvanceAmount}</td>
                  <td>{summary.totalNetAmount}</td>
                  <td>{summary.totalBalance}</td> {/* Total balance cell */}
                </tr>
              </>
            ) : (
              <tr style={{ backgroundColor: '#f0f0f0' }}>
                <td colSpan="4"><strong>Total for {filters.customer_name}:</strong></td>
                <td>{summary.totalBillAmount}</td>
                <td>{summary.totalTaxAmount}</td>
                <td>{summary.totalAdvanceAmount}</td>
                <td>{summary.totalNetAmount}</td>
                <td>{summary.totalBalance}</td> {/* Total balance cell in summary mode */}
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BillPaymentDetailsReport;
