import React, { useState, useEffect } from 'react';
import './styles/RateDetails.css';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const RateDetails = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [rateDetails, setRateDetails] = useState({});
  const [isEditing, setIsEditing] = useState({});

  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + 'client-master')
      .then(response => setClients(response.data))
      .catch(error => console.error('Error fetching clients:', error));
  }, []);

  const handleClientChange = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);

    axios.get(BASE_URL + `rate-master/${clientId}`)
      .then(response => {
        const groupedData = {};
        response.data.forEach(row => {
          if (!groupedData[row.invoice_head_id]) {
            groupedData[row.invoice_head_id] = { invoice_head_name: row.invoice_head_name, TAX: 0 };
          }
          groupedData[row.invoice_head_id][row.column_name] = row.rate;
          groupedData[row.invoice_head_id]['TAX'] = row.tax;
        });
        setRateDetails(groupedData);
      })
      .catch(error => console.error('Error fetching rate details:', error));
  };

  const handleEditClick = (invoiceHeadId) => {
    setIsEditing({ ...isEditing, [invoiceHeadId]: true });
  };

  const handleSaveClick = (invoiceHeadId) => {
    const updatedRates = rateDetails[invoiceHeadId];
    const clientId = selectedClient;
  
    if (!clientId) {
      console.error('Client ID is missing.');
      alert('Client ID is required to update rates.');
      return;
    }
  
    const updates = [
      'I-20', 'I-40', 'I-LCL', 'I-AIR', 'DTA', 'I-TRANSFER', 'I-COURIER', 'E-20', 'E-40', 'E-LCL', 'E-AIR', 'BOE', 'E-TRANSFER', 'E-COURIER'
    ].map((column) => ({
      column_name: column,
      rate: updatedRates[column] || '', 
      tax: updatedRates['TAX'],
    }));
  
    axios.put(process.env.REACT_APP_BACKEND_URL + 'rate-master', { invoiceHeadId, updates, clientId })
      .then(() => {
        setIsEditing({ ...isEditing, [invoiceHeadId]: false });
        alert('Rate details updated successfully');
      })
      .catch((error) => console.error('Error updating rate details:', error));
  };

  const handleRateChange = (invoiceHeadId, columnName, value) => {
    setRateDetails((prevDetails) => ({
      ...prevDetails,
      [invoiceHeadId]: {
        ...prevDetails[invoiceHeadId],
        [columnName]: columnName === 'TAX' ? (value ? 1 : 0) : value,
      },
    }));
  };

  const downloadPDF = () => {
    console.log('Selected Client ID:', selectedClient);
    console.log('Clients List:', clients);
  
    const client = clients.find(client => String(client.id) === String(selectedClient));
    console.log('Found Client:', client); 
  
    const clientName = client ? client.client_name : 'Unknown Client';
  
    const doc = new jsPDF();
    doc.text(`Rate Details for Client: ${clientName}`, 20, 10);
  
    doc.autoTable({
      head: [['Invoice Head', 'I-20', 'I-40', 'I-LCL', 'I-AIR', 'DTA', 'I-TRANSFER', 'I-COURIER', 'E-20', 'E-40', 'E-LCL', 'E-AIR', 'BOE', 'E-TRANSFER', 'E-COURIER', 'Tax']],
      body: Object.entries(rateDetails).map(([invoiceHeadId, details]) => [
        details.invoice_head_name,
        details['I-20'] || '',
        details['I-40'] || '',
        details['I-LCL'] || '',
        details['I-AIR'] || '',
        details['DTA'] || '',
        details['I-TRANSFER'] || '',
        details['I-COURIER'] || '',
        details['E-20'] || '',
        details['E-40'] || '',
        details['E-LCL'] || '',
        details['E-AIR'] || '',
        details['BOE'] || '',
        details['E-TRANSFER'] || '',
        details['E-COURIER'] || '',
        details.TAX ? 'Yes' : 'No'
      ])
    });
    doc.save(`${clientName} - RateDetails.pdf`);
  };

  const downloadExcel = () => {
    const client = clients.find(client => String(client.id) === String(selectedClient));
    console.log('Found Client for Excel:', client);
  
    const clientName = client ? client.client_name : 'Unknown_Client';
  
    const worksheetData = [
      ['Invoice Head', 'I-20', 'I-40', 'I-LCL', 'I-AIR', 'DTA', 'I-TRANSFER', 'I-COURIER', 'E-20', 'E-40', 'E-LCL', 'E-AIR', 'BOE', 'E-TRANSFER', 'E-COURIER', 'Tax'],
      ...Object.entries(rateDetails).map(([invoiceHeadId, details]) => [
        details.invoice_head_name,
        details['I-20'] || '',
        details['I-40'] || '',
        details['I-LCL'] || '',
        details['I-AIR'] || '',
        details['DTA'] || '',
        details['I-TRANSFER'] || '',
        details['I-COURIER'] || '',
        details['E-20'] || '',
        details['E-40'] || '',
        details['E-LCL'] || '',
        details['E-AIR'] || '',
        details['BOE'] || '',
        details['E-TRANSFER'] || '',
        details['E-COURIER'] || '',
        details.TAX ? 'Yes' : 'No'
      ])
    ];
  
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Rate Details');
    XLSX.writeFile(wb, `${clientName} - RateDetails.xlsx`);
  };

  return (
    <div className="rate-details">
      <h1 className="rate-details-title">Rate Details</h1>
      <div className="rate-details-client-selection">
        <label className="rate-details-label">Select Client:</label>
        <select
          className="rate-details-select"
          onChange={handleClientChange}
          value={selectedClient}
        >
          <option value="">Select Client</option>
          {clients.map(client => (
            <option key={client.id} value={client.id}>
              {client.client_name}
            </option>
          ))}
        </select>
      </div>

      {Object.keys(rateDetails).length > 0 && (
        <>
          <table className="rate-details-table">
            <thead>
              <tr>
                <th>Invoice Head</th>
                <th>I-20</th>
                <th>I-40</th>
                <th>I-LCL</th>
                <th>I-AIR</th>
                <th>DTA</th>
                <th>I-TRANSFER</th>
                <th>I-COURIER</th>
                <th>E-20</th>
                <th>E-40</th>
                <th>E-LCL</th>
                <th>E-AIR</th>
                <th>BOE</th>
                <th>E-TRANSFER</th>
                <th>E-COURIER</th>
                <th>Tax</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(rateDetails).map(([invoiceHeadId, details]) => (
                <tr key={invoiceHeadId}>
                  <td>{details.invoice_head_name}</td>
                  {['I-20', 'I-40', 'I-LCL', 'I-AIR', 'DTA', 'I-TRANSFER', 'I-COURIER', 'E-20', 'E-40', 'E-LCL', 'E-AIR', 'BOE', 'E-TRANSFER', 'E-COURIER'].map((col) => (
                    <td key={col}>
                      {isEditing[invoiceHeadId] ? (
                        <input
                          type="text"
                          value={details[col] || ''}
                          onChange={(e) => handleRateChange(invoiceHeadId, col, e.target.value)}
                        />
                      ) : (
                        details[col] || ''
                      )}
                    </td>
                  ))}
                  <td>
                    {isEditing[invoiceHeadId] ? (
                      <input
                        type="checkbox"
                        checked={!!details.TAX}
                        onChange={(e) => handleRateChange(invoiceHeadId, 'TAX', e.target.checked)}
                      />
                    ) : (
                      details.TAX ? 'Yes' : 'No'
                    )}
                  </td>
                  <td>
                    {isEditing[invoiceHeadId] ? (
                      <button onClick={() => handleSaveClick(invoiceHeadId)}>Save</button>
                    ) : (
                      <button onClick={() => handleEditClick(invoiceHeadId)}>Edit</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='rate-button-container'>
          <button className='rate-details-button-pdf' onClick={downloadPDF}>Download as PDF</button>
          <button className='rate-details-button-xlx' onClick={downloadExcel}>Download as Excel</button>
          </div>
        </>
      )}
    </div>
  );
};

export default RateDetails;
