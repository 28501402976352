import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/JobDetailsReport.css';
import { jsPDF } from 'jspdf'; 
import * as XLSX from 'xlsx';  
import 'jspdf-autotable';
import './styles/JobDetailsReport.css';

const JobDetailsReport = () => {
  const [jobType, setJobType] = useState('Both');
  const [dateFilter, setDateFilter] = useState('equal');
  const [date, setDate] = useState('');
  const [importerName, setImporterName] = useState('');
  const [year, setYear] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);

  const downloadPDF = () => {
    const doc = new jsPDF('l', 'mm', 'a4');
  
    doc.text('Job Details Report', 14, 15);
  
    const columns1 = [
      'Date', 'Service Type', 'Import No', 'Job ID', 'Job Date', 'Transport Mode', 'Branch', 'BE Type',
      'Importer Name', 'Consignor Name', 'Ref No'
    ];
  
    const columns2 = [
      'Country of Shipment', 'Port of Shipment', 'Mother Vessel Name', 'V No', 'ETA', 'Feeder Vessel Name',
      'Feeder V No', 'Feeder ETA', 'Forwarder', 'Shipping Line', 'Rotation', 'Rotation Date', 'Line No', 'MBL No',
      'MBL Date', 'HBL No', 'HBL Date'
    ];
  
    const columns3 = [
      'CBM',  'Net Wt', 'Net Wt Unit', 'Gross Wt', 'Gross Wt Unit', 'Num of Pkgs', 'Num of Pkgs Unit', 'Container No',
      'STC', 'STC Unit', 'STC2', 'STC2 Unit', 'Size 20', 'Size 40', 'Total Container'
    ];
  
    const columns4 = [
       'Terms', 'Currency', 'Invoice', 'Invoice Date', 'PO', 'PO Date', 'Invoice Description', 'Qty', 'Unit',
      'Unit Price', 'Unit Value', 'Freight', 'Insurance', 'Other Charges', 'FOB Value', 'BE No', 'BE Date', 'Value',
      'Duty Amount', 'TR6 Challan No', 'TR6 Challan Date', 'Goods Received On', 'Cleared On', 'Transporter Name'
    ];
  
    const rows = results.map((result) => [
      result.date, result.service_type, result.import_no, result.job_id, result.job_date, result.trans_mode, result.branch, 
      result.be_type, result.importer_name, result.consignor_name, result.ref_no, result.country_of_shipment, 
      result.port_of_shipment, result.mother_vessel_name, result.v_no, result.eta, result.feeder_vessel_name, 
      result.feeder_v_no, result.feeder_eta, result.forwarder, result.shipping_line, result.rotation, result.rotation_date, 
      result.line_no, result.mbl_no, result.mbl_date, result.hbl_no, result.hbl_date, result.cbm, result.cfs, result.net_wt, result.net_wt_unit, result.gross_wt, result.gross_wt_unit, result.num_of_pkgs, result.num_of_pkgs_unit, 
      result.container_no, result.stc, result.stc_unit, result.stc2, result.stc2_unit, result.size_20, result.size_40, 
      result.total_container, result.remarks, result.terms, result.currency, result.invoice, result.invoice_date, 
      result.po, result.po_date, result.invoice_description, result.qty, result.unit, result.unit_price, result.unit_value, 
      result.freight, result.insurance, result.other_charges, result.fob_value, result.be_no, result.be_date, result.value, 
      result.duty_amount, result.tr6_challan_no, result.tr6_challan_date, result.goods_rcvd_on, 
      result.cleared_on ? result.cleared_on : 'Uncleared', result.transporter_name
    ]);
  
    doc.autoTable({
      head: [columns1],
      body: rows.map(row => row.slice(0, 11)),
      startY: 20,
      styles: { fontSize: 7, cellPadding: 2 },
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      headStyles: { fillColor: [22, 160, 133] },
      didDrawPage: function (data) {
        if (data.pageNumber > 1) {
          doc.text('Job Details Report (Continued)', 14, 15);
        }
      }
    });
  
    doc.addPage();
    doc.autoTable({
      head: [columns2],
      body: rows.map(row => row.slice(11, 29)),
      startY: 20,
      styles: { fontSize: 7, cellPadding: 2 },
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      headStyles: { fillColor: [22, 160, 133] }
    });
  
    doc.addPage();
    doc.autoTable({
      head: [columns3],
      body: rows.map(row => row.slice(29, 45)),
      startY: 20,
      styles: { fontSize: 7, cellPadding: 2 },
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      headStyles: { fillColor: [22, 160, 133] }
    });
  
    doc.addPage();
    doc.autoTable({
      head: [columns4],
      body: rows.map(row => row.slice(45)),
      startY: 20,
      styles: { fontSize: 7, cellPadding: 2 },
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      headStyles: { fillColor: [22, 160, 133] }
    });
  
    doc.save('JobDetailsReport.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(results);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'JobDetails');
    XLSX.writeFile(workbook, 'JobDetailsReport.xlsx');
  };

  const handleSearch = async () => {
    setLoading(true);
    setError('');

    try {
      const params = { jobType, dateFilter, date, importerName, year };
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + 'import-job/job-details-report', { params });
      setResults(response.data);
      setTotalRecords(response.data.length);
    } catch (err) {
      setError('Error fetching data. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="job-report-details">
    <h1 className="job-report-header">Job Details Report</h1>
  
    <div className="job-report-filter-group">
      <div className="job-report-filter">
        <label>Job Type</label>
        <select value={jobType} onChange={(e) => setJobType(e.target.value)} className="job-report-select">
          <option value="Both">Both</option>
          <option value="Cleared">Cleared</option>
          <option value="Uncleared">Uncleared</option>
        </select>
      </div>
  
      <div className="job-report-filter">
        <label>Date</label>
        <select value={dateFilter} onChange={(e) => setDateFilter(e.target.value)} className="job-report-select">
          <option value="before">Before</option>
          <option value="equal">Equal</option>
          <option value="after">After</option>
        </select>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="job-report-input"
        />
      </div>
  
      <div className="job-report-filter">
        <label>Customer</label>
        <input
          type="text"
          value={importerName}
          onChange={(e) => setImporterName(e.target.value)}
          className="job-report-input"
        />
      </div>
  
      <div className="job-report-filter">
        <label>Year</label>
        <select value={year} onChange={(e) => setYear(e.target.value)} className="job-report-select">
          <option value="">Select Year</option>
          <option value="2023-2024">2023-2024</option>
          <option value="2024-2025">2024-2025</option>
        </select>
      </div>
  
      <button onClick={handleSearch} disabled={loading} className="job-report-button">View</button>

    </div>
    <div className="all-voucher-button-group">
    <button onClick={downloadPDF} className="file-upload-download-button">Download PDF</button>
    <button onClick={downloadExcel} className="file-upload-download-button">Download Excel</button>
    </div>
  
    {error && <div className="job-report-error-message">{error}</div>}
  
    {loading ? (
      <div className="job-report-loading">Loading...</div>
    ) : (
      <>
      <div className="total-records">
        Total Records Found: {totalRecords}
      </div>
      <div className="job-report-table-container">
        <table className="job-report-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Service Type</th>
              <th>Import No</th>
              <th>Job ID</th>
              <th>Job Date</th>
              <th>Transport Mode</th>
              <th>Branch</th>
              <th>BE Type</th>
              <th>Importer Name</th>
              <th>Consignor Name</th>
              <th>Ref No</th>
              <th>Country of Shipment</th>
              <th>Port of Shipment</th>
              <th>Mother Vessel Name</th>
              <th>V No</th>
              <th>ETA</th>
              <th>Feeder Vessel Name</th>
              <th>Feeder V No</th>
              <th>Feeder ETA</th>
              <th>Forwarder</th>
              <th>Shipping Line</th>
              <th>Rotation</th>
              <th>Rotation Date</th>
              <th>Line No</th>
              <th>MBL No</th>
              <th>MBL Date</th>
              <th>HBL No</th>
              <th>HBL Date</th>
              <th>CBM</th>
              <th>CFS</th>
              <th>Net Wt</th>
              <th>Net Wt Unit</th>
              <th>Gross Wt</th>
              <th>Gross Wt Unit</th>
              <th>Num of Pkgs</th>
              <th>Num of Pkgs Unit</th>
              <th>Container No</th>
              <th>STC</th>
              <th>STC Unit</th>
              <th>STC2</th>
              <th>STC2 Unit</th>
              <th>Size 20</th>
              <th>Size 40</th>
              <th>Total Container</th>
              <th>Remarks</th>
              <th>Terms</th>
              <th>Currency</th>
              <th>Invoice</th>
              <th>Invoice Date</th>
              <th>PO</th>
              <th>PO Date</th>
              <th>Invoice Description</th>
              <th>Qty</th>
              <th>Unit</th>
              <th>Unit Price</th>
              <th>Unit Value</th>
              <th>Freight</th>
              <th>Insurance</th>
              <th>Other Charges</th>
              <th>FOB Value</th>
              <th>BE No</th>
              <th>BE Date</th>
              <th>Value</th>
              <th>Duty Amount</th>
              <th>TR6 Challan No</th>
              <th>TR6 Challan Date</th>
              <th>Goods Received On</th>
              <th>Cleared On</th>
              <th>Transporter Name</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => (
              <tr key={index}>
                <td>{new Date(result.date).toLocaleDateString()}</td>
                <td>{result.service_type}</td>
                <td>{result.import_no}</td>
                <td>{result.job_id}</td>
                <td>{new Date(result.job_date).toLocaleDateString()}</td>
                <td>{result.trans_mode}</td>
                <td>{result.branch}</td>
                <td>{result.be_type}</td>
                <td>{result.importer_name}</td>
                <td>{result.consignor_name}</td>
                <td>{result.ref_no}</td>
                <td>{result.country_of_shipment}</td>
                <td>{result.port_of_shipment}</td>
                <td>{result.mother_vessel_name}</td>
                <td>{result.v_no}</td>
                <td>{new Date(result.eta).toLocaleDateString()}</td>
                <td>{result.feeder_vessel_name}</td>
                <td>{result.feeder_v_no}</td>
                <td>{new Date(result.feeder_eta).toLocaleDateString()}</td>
                <td>{result.forwarder}</td>
                <td>{result.shipping_line}</td>
                <td>{result.rotation}</td>
                <td>{result.rotation_date}</td>
                <td>{result.line_no}</td>
                <td>{result.mbl_no}</td>
                <td>{new Date(result.mbl_date).toLocaleDateString()}</td>
                <td>{result.hbl_no}</td>
                <td>{new Date(result.hbl_date).toLocaleDateString()}</td>
                <td>{result.cbm}</td>
                <td>{result.cfs}</td>
                <td>{result.net_wt}</td>
                <td>{result.net_wt_unit}</td>
                <td>{result.gross_wt}</td>
                <td>{result.gross_wt_unit}</td>
                <td>{result.num_of_pkgs}</td>
                <td>{result.num_of_pkgs_unit}</td>
                <td>{result.container_no}</td>
                <td>{result.stc}</td>
                <td>{result.stc_unit}</td>
                <td>{result.stc2}</td>
                <td>{result.stc2_unit}</td>
                <td>{result.size_20}</td>
                <td>{result.size_40}</td>
                <td>{result.total_container}</td>
                <td>{result.remarks}</td>
                <td>{result.terms}</td>
                <td>{result.currency}</td>
                <td>{result.invoice}</td>
                <td>{new Date(result.invoice_date).toLocaleDateString()}</td>
                <td>{result.po}</td>
                <td>{new Date(result.po_date).toLocaleDateString()}</td>
                <td>{result.invoice_description}</td>
                <td>{result.qty}</td>
                <td>{result.unit}</td>
                <td>{result.unit_price}</td>
                <td>{result.unit_value}</td>
                <td>{result.freight}</td>
                <td>{result.insurance}</td>
                <td>{result.other_charges}</td>
                <td>{result.fob_value}</td>
                <td>{result.be_no}</td>
                <td>{new Date(result.be_date).toLocaleDateString()}</td>
                <td>{result.value}</td>
                <td>{result.duty_amount}</td>
                <td>{result.tr6_challan_no}</td>
                <td>{new Date(result.tr6_challan_date).toLocaleDateString()}</td>
                <td>{new Date(result.goods_rcvd_on).toLocaleDateString()}</td>
                <td>{result.cleared_on ? new Date(result.cleared_on).toLocaleDateString() : 'Uncleared'}</td>
                <td>{result.transporter_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </>
    )}
  </div>
  
  );
};

export default JobDetailsReport;
