import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './styles/JobView.css';
import axios from 'axios';

const JobView = () => {
  const { ref_no } = useParams(); // Retrieve ref_no from URL params
  const [jobDetails, setJobDetails] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch job details when component loads
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}billing/billingDetails/job/${ref_no}`)
      .then((response) => {
        setJobDetails(response.data); 
      })
      .catch((err) => {
        console.error('Error fetching job details:', err);
        setError('Failed to fetch job details.');
      });
  }, [ref_no]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!jobDetails) {
    return <div>Loading...</div>;
  }

  const { import_job, doc_details, clearance_details } = jobDetails;

  return (
<div className="job-view-container">
  <h1>Job Details for Reference Number: {import_job.ref_no}</h1>

  <div className="job-view-columns">
    <div className="job-view-column">
      <h2>Import Job Details</h2>
      <table className="job-view-table">
        <tbody>
          <tr><td><strong>Date:</strong></td><td>{import_job.date}</td></tr>
          <tr><td><strong>Service Type:</strong></td><td>{import_job.service_type}</td></tr>
          <tr><td><strong>Import Number:</strong></td><td>{import_job.import_no}</td></tr>
          <tr><td><strong>Job ID:</strong></td><td>{import_job.job_id}</td></tr>
          <tr><td><strong>Job Date:</strong></td><td>{import_job.job_date}</td></tr>
          <tr><td><strong>Transport Mode:</strong></td><td>{import_job.trans_mode}</td></tr>
          <tr><td><strong>Branch:</strong></td><td>{import_job.branch}</td></tr>
          <tr><td><strong>BE Type:</strong></td><td>{import_job.be_type}</td></tr>
          <tr><td><strong>Importer Name:</strong></td><td>{import_job.importer_name}</td></tr>
          <tr><td><strong>Consignor Name:</strong></td><td>{import_job.consignor_name}</td></tr>
          <tr><td><strong>Country of Shipment:</strong></td><td>{import_job.country_of_shipment}</td></tr>
          <tr><td><strong>Port of Shipment:</strong></td><td>{import_job.port_of_shipment}</td></tr>
          <tr><td><strong>Mother Vessel Name:</strong></td><td>{import_job.mother_vessel_name}</td></tr>
          <tr><td><strong>Vehicle Number:</strong></td><td>{import_job.v_no}</td></tr>
          <tr><td><strong>ETA:</strong></td><td>{import_job.eta}</td></tr>
          <tr><td><strong>Remainder:</strong></td><td>{import_job.remainder}</td></tr>
          <tr><td><strong>Feeder Vessel Name:</strong></td><td>{import_job.feeder_vessel_name}</td></tr>
          <tr><td><strong>Feeder Vehicle Number:</strong></td><td>{import_job.feeder_v_no}</td></tr>
          <tr><td><strong>Feeder ETA:</strong></td><td>{import_job.feeder_eta}</td></tr>
          <tr><td><strong>Forwarder:</strong></td><td>{import_job.forwarder}</td></tr>
          <tr><td><strong>Shipping Line:</strong></td><td>{import_job.shipping_line}</td></tr>
          <tr><td><strong>Rotation:</strong></td><td>{import_job.rotation}</td></tr>
          <tr><td><strong>Rotation Date:</strong></td><td>{import_job.rotation_date}</td></tr>
          <tr><td><strong>Line Number:</strong></td><td>{import_job.line_no}</td></tr>
          <tr><td><strong>MBL Number:</strong></td><td>{import_job.mbl_no}</td></tr>
          <tr><td><strong>MBL Date:</strong></td><td>{import_job.mbl_date}</td></tr>
          <tr><td><strong>HBL Number:</strong></td><td>{import_job.hbl_no}</td></tr>
          <tr><td><strong>HBL Date:</strong></td><td>{import_job.hbl_date}</td></tr>
          <tr><td><strong>CBM:</strong></td><td>{import_job.cbm}</td></tr>
          <tr><td><strong>CFS:</strong></td><td>{import_job.cfs}</td></tr>
          <tr><td><strong>Net Weight:</strong></td><td>{import_job.net_wt}</td></tr>
          <tr><td><strong>Net Weight Unit:</strong></td><td>{import_job.net_wt_unit}</td></tr>
          <tr><td><strong>Gross Weight:</strong></td><td>{import_job.gross_wt}</td></tr>
          <tr><td><strong>Gross Weight Unit:</strong></td><td>{import_job.gross_wt_unit}</td></tr>
          <tr><td><strong>Number of Packages:</strong></td><td>{import_job.num_of_pkgs}</td></tr>
          <tr><td><strong>Number of Packages Unit:</strong></td><td>{import_job.num_of_pkgs_unit}</td></tr>
          <tr><td><strong>Container Number:</strong></td><td>{import_job.container_no}</td></tr>
          <tr><td><strong>STC:</strong></td><td>{import_job.stc}</td></tr>
          <tr><td><strong>STC Unit:</strong></td><td>{import_job.stc_unit}</td></tr>
          <tr><td><strong>STC2:</strong></td><td>{import_job.stc2}</td></tr>
          <tr><td><strong>STC2 Unit:</strong></td><td>{import_job.stc2_unit}</td></tr>
          <tr><td><strong>Size 20:</strong></td><td>{import_job.size_20}</td></tr>
          <tr><td><strong>Size 40:</strong></td><td>{import_job.size_40}</td></tr>
          <tr><td><strong>Total Container:</strong></td><td>{import_job.total_container}</td></tr>
          <tr><td><strong>Remarks:</strong></td><td>{import_job.remarks}</td></tr>
          <tr><td><strong>Terms:</strong></td><td>{import_job.terms}</td></tr>
          <tr><td><strong>Currency:</strong></td><td>{import_job.currency}</td></tr>
          <tr><td><strong>Invoice:</strong></td><td>{import_job.invoice}</td></tr>
          <tr><td><strong>Invoice Date:</strong></td><td>{import_job.invoice_date}</td></tr>
          <tr><td><strong>PO:</strong></td><td>{import_job.po}</td></tr>
          <tr><td><strong>PO Date:</strong></td><td>{import_job.po_date}</td></tr>
          <tr><td><strong>Invoice Description:</strong></td><td>{import_job.invoice_description}</td></tr>
          <tr><td><strong>Quantity:</strong></td><td>{import_job.qty}</td></tr>
          <tr><td><strong>Unit:</strong></td><td>{import_job.unit}</td></tr>
          <tr><td><strong>Unit Price:</strong></td><td>{import_job.unit_price}</td></tr>
          <tr><td><strong>Unit Value:</strong></td><td>{import_job.unit_value}</td></tr>
          <tr><td><strong>Freight:</strong></td><td>{import_job.freight}</td></tr>
          <tr><td><strong>Insurance:</strong></td><td>{import_job.insurance}</td></tr>
          <tr><td><strong>Other Charges:</strong></td><td>{import_job.other_charges}</td></tr>
          <tr><td><strong>FOB Value:</strong></td><td>{import_job.fob_value}</td></tr>
        </tbody>
      </table>
    </div>

    <div className="job-view-column">
      <h2>Document Details</h2>
      <table className="job-view-table">
        <tbody>
          <tr><td><strong>BE Number:</strong></td><td>{doc_details.be_no}</td></tr>
          <tr><td><strong>BE Date:</strong></td><td>{doc_details.be_date}</td></tr>
          <tr><td><strong>Value:</strong></td><td>{doc_details.value}</td></tr>
          <tr><td><strong>Duty Amount:</strong></td><td>{doc_details.duty_amount}</td></tr>
          <tr><td><strong>TR6 Challan Number:</strong></td><td>{doc_details.tr6_challan_no}</td></tr>
          <tr><td><strong>TR6 Challan Date:</strong></td><td>{doc_details.tr6_challan_date}</td></tr>
          <tr><td><strong>Goods Received On:</strong></td><td>{doc_details.goods_rcvd_on}</td></tr>
        </tbody>
      </table>
    </div>

    <div className="job-view-column">
      <h2>Clearance Details</h2>
      <table className="job-view-table">
        <tbody>
          <tr><td><strong>Cleared On:</strong></td><td>{clearance_details.cleared_on}</td></tr>
          <tr><td><strong>Transporter Name:</strong></td><td>{clearance_details.transporter_name}</td></tr>
          <tr><td><strong>Vehicle Number:</strong></td><td>{clearance_details.v_no}</td></tr>
          <tr><td><strong>Halting Days:</strong></td><td>{clearance_details.h_days}</td></tr>
          <tr><td><strong>Amount:</strong></td><td>{clearance_details.amount}</td></tr>
          <tr><td><strong>Advance:</strong></td><td>{clearance_details.advance}</td></tr>
          <tr><td><strong>Balance:</strong></td><td>{clearance_details.balance}</td></tr>
          <tr><td><strong>Incidental Description:</strong></td><td>{clearance_details.incidental_description}</td></tr>
          <tr><td><strong>Incidental Amount:</strong></td><td>{clearance_details.incidental_amount}</td></tr>
          <tr><td><strong>OT Details:</strong></td><td>{clearance_details.ot_details}</td></tr>
          <tr><td><strong>Charges Description:</strong></td><td>{clearance_details.charges_description}</td></tr>
          <tr><td><strong>Agent Name:</strong></td><td>{clearance_details.agent_name}</td></tr>
          <tr><td><strong>Receipt:</strong></td><td>{clearance_details.rcpt}</td></tr>
          <tr><td><strong>Receipt Date:</strong></td><td>{clearance_details.rcpt_date}</td></tr>
          <tr><td><strong>Charges Amount:</strong></td><td>{clearance_details.charges_amount}</td></tr>
          <tr><td><strong>Halting Days:</strong></td><td>{clearance_details.halting_days}</td></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


  );
};

export default JobView;
