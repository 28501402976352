import React, { useState, useCallback } from 'react'; 
import './styles/BillPayments.css';
import axios from 'axios';

const BillPayments = () => {
  const [filters, setFilters] = useState({
    paymentDateOption: 'equal',
    paymentDate: '',
    paid_date: '',
    refNo: '',
    customerName: '',
    year: '2023-2024',
    billStatus: '',
  });

  const [bills, setBills] = useState([]);
  const [editedBills, setEditedBills] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchBills = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const params = {
        ...filters,
        ...(filters.paymentDate && {
          paymentDate: filters.paymentDate,
          paymentDateOption: filters.paymentDateOption,
        }),
      };
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}billing/billPayments/bill-payments`, { params });
      const activeBills = response.data.filter(bill => bill.delete_status === 'No');
      setBills(activeBills);
    } catch (error) {
      setError('Error fetching bills.');
      console.error('Error fetching bills:', error);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleEditChange = (billId, field, value) => {
    setEditedBills((prevEditedBills) => ({
      ...prevEditedBills,
      [billId]: {
        ...prevEditedBills[billId],
        [field]: value, 
      },
    }));
  };

  const handleSave = async (bill_no) => {
    const billData = editedBills[bill_no] || {};
  
    const originalBill = bills.find(bill => bill.bill_no === bill_no);
  
    const balance = calculateBalance(
      originalBill.total_amount, 
      originalBill.advance_amount, 
      billData.tax ?? originalBill.tax, 
      billData.rcvd_amount ?? originalBill.rcvd_amount
    );
  
    const approval_status = balance === '0.00' ? 'Paid' : originalBill.approval_status;
    const paid_date = balance === '0.00' ? new Date().toISOString().split('T')[0] : originalBill.paid_date;
  
    const finalBillData = {
      ...originalBill,
      ...billData,
      approval_status: approval_status,
      paid_date: paid_date,
      balance: balance
    };
  
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}billing/billPayments/update-bill`, finalBillData);
      alert('Bill saved successfully');
      fetchBills(); 
    } catch (error) {
      console.error('Error saving bill:', error);
    }
  };
  
  
  const calculateBalance = (totalAmount = 0, advanceAmount = 0, tax = 0, rcvdAmount = 0) => {
    const taxValue = parseFloat(tax) || 0;  
    const receivedValue = parseFloat(rcvdAmount) || 0;
    const balance = parseFloat(totalAmount) - parseFloat(advanceAmount) - taxValue - receivedValue;
    return isNaN(balance) ? '0.00' : balance.toFixed(2);
  };
  
  return (
<div className="bill-payments-container">
  <form className="bill-payments-form" onSubmit={(e) => { e.preventDefault(); fetchBills(); }}>
    <h2 className="bill-payments-header">Bill Payments</h2>
    
    <div className="bill-pay-form-group">
      <label>Date Option:</label>
      <select name="paymentDateOption" className="bill-payments-select" value={filters.paymentDateOption} onChange={handleFilterChange}>
        <option value="equal">Equal</option>
        <option value="before">Before</option>
        <option value="after">After</option>
      </select>
    </div>

    <div className="bill-pay-form-group">  
      <label>Date:</label>
      <input type="date" name="paymentDate" className="bill-payments-input" value={filters.paymentDate} onChange={handleFilterChange} />
    </div>

    <div className="bill-pay-form-group">  
      <label>Paid Date:</label>
      <input type="date" name="paid_date" className="bill-payments-input" value={filters.paid_date} onChange={handleFilterChange} />
    </div>

    <div className="bill-pay-form-group">
      <label>Ref No:</label>
      <input type="text" name="refNo" className="bill-payments-input" value={filters.refNo} onChange={handleFilterChange} />
    </div>

    <div className="bill-pay-form-group">
      <label>Customer Name:</label>
      <input type="text" name="customerName" className="bill-payments-input" value={filters.customerName} onChange={handleFilterChange} />
    </div>

    <div className="bill-pay-form-group">  
      <label>Year:</label>
      <select name="year" className="bill-payments-select" value={filters.year} onChange={handleFilterChange}>
        <option value="2023-2024">2023-2024</option>
        <option value="2024-2025">2024-2025</option>
      </select>
    </div>

    <div className="bill-pay-form-group">  
      <label>Bill Status:</label>
      <input type="text" name="billStatus" className="bill-payments-input" value={filters.billStatus} onChange={handleFilterChange} />
    </div>

    <button type="submit" className="bill-payments-submit-button">Show</button>
  </form>

  {loading ? (
    <p className="bill-payments-loading">Loading...</p>
  ) : error ? (
    <p className="bill-payments-error">{error}</p>
  ) : (
    <table className="bill-payments-table">
      <thead>
        <tr>
          <th>Bill No.</th>
          <th>Date</th>
          <th>Ref No.</th>
          <th>Customer Name</th>
          <th>Total Amount</th>
          <th>Advance Amount</th>
          <th>TDS</th>
          <th>Received Amount</th>
          <th>Deduction</th>
          <th>Cheque</th>
          <th>Cheque Date</th>
          <th>Balance</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {bills.map((bill) => {
          const taxValue = editedBills[bill.bill_no]?.tax || bill.tax || 0;
          const rcvdAmountValue = editedBills[bill.bill_no]?.rcvd_amount || bill.rcvd_amount || 0;

          return (
            <tr key={bill.bill_no}>
              <td>{bill.bill_no}</td>
              <td>{new Date(bill.date).toLocaleDateString()}</td>
              <td>{bill.ref_no}</td>
              <td>{bill.customer_name}</td>
              <td>{bill.total_amount}</td>
              <td>{bill.advance_amount}</td>
              <td>
                <input
                  type="number"
                  value={editedBills[bill.bill_no]?.tax || bill.tax || 0}
                  onChange={(e) => handleEditChange(bill.bill_no, 'tax', e.target.value)}
                  className="bill-payments-edit-input"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={editedBills[bill.bill_no]?.rcvd_amount || bill.rcvd_amount || ''}
                  onChange={(e) => handleEditChange(bill.bill_no, 'rcvd_amount', e.target.value)}
                  className="bill-payments-edit-input"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={editedBills[bill.bill_no]?.deduction || bill.deduction || ''}
                  onChange={(e) => handleEditChange(bill.bill_no, 'deduction', e.target.value)}
                  className="bill-payments-edit-input"
                />
              </td>
              <td>
                <input
                  type="text"
                  value={editedBills[bill.bill_no]?.cheque || bill.cheque || ''}
                  onChange={(e) => handleEditChange(bill.bill_no, 'cheque', e.target.value)}
                  className="bill-payments-edit-input"
                />
              </td>
              <td>
                <input
                  type="date"
                  value={editedBills[bill.bill_no]?.cheque_date || bill.cheque_date || ''}
                  onChange={(e) => handleEditChange(bill.bill_no, 'cheque_date', e.target.value)}
                  className="bill-payments-edit-input"
                />
              </td>
              <td>
                {calculateBalance(bill.total_amount, bill.advance_amount, taxValue, rcvdAmountValue)}
              </td>
              <td>
                <button onClick={() => handleSave(bill.bill_no)} className="bill-payments-save-button">Save</button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  )}
</div>

);
};

export default BillPayments;
