import React from 'react';

function NoAccess() {
  return (
    <div>
      <h2>You do not have access to this page.</h2>
    </div>
  );
}

export default NoAccess;
